<template>
  <div class="information_list">
    <div
      v-for="(item, index) in video_list"
      :key="index"
      class="information_item"
      :class="{ active: item.id === active_video.id }"
      @click="openVideo(item)"
    >
      <div class="information_item_left">
        <img :src="item.videoCover" />
        <div v-show="item.id === active_video.id" class="playing_tag">
          <img class="playing_icon" src="../../assets/images/play.gif" alt="" />
          播放中
        </div>
      </div>
      <div class="information_item_right">
        <p class="title">
          {{ item.videoName | fileName }}
        </p>
        <p class="date">
          {{ item.videoTime }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import { Toast } from 'vant';
import { mapState } from "vuex";
// import Configs from '@/config';
// import { getVideoList } from "@/api";

export default {
  data() {
    return {
      list: [],
    };
  },
  computed: mapState({
    // user: state => state.users.info,
    serviceConfig: "serviceConfig",
    video_list: (state) => state.live.video_list,
    active_video: (state) => state.live.active_video,
  }),
  watch: {
    serviceConfig: {
      handler(val) {
        if (val.id) {
          this.getVideoList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getVideoList() {
      this.$store.dispatch("getVideoList");
    },
    openVideo(item) {
      this.$store.commit("setActiveVideo", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin setBottomLine($c: #c7c7c7) {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 1px;
  border-bottom: 1px solid $c;
  color: $c;
  transform-origin: 0 100%;
  transform: scaleY(0.5);
}

// .information_list {
//     background: #fff;
// }

.information_item {
  display: flex;
  padding: 10px 15px;
  position: relative;
  // border-bottom: 1px solid $border-color-base;
  &::after {
    @include setBottomLine($border-color-base);
  }

  &.active {
    .information_item_right {
      .title {
        color: $theme;
      }
    }

    .information_item_left {
      position: relative;
      .playing_tag {
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 50px;
        height: 17px;
        background: $theme;
        border-radius: 0px 4px 4px 0px;
        font-size: 10px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #fff;
        line-height: 18px;

        img {
          width: 9px;
          height: 9px;
          margin-left: 3px;
        }
      }
    }
  }
}
.information_item_left {
  margin-right: 9px;
  width: 137px;
  height: 86px;
  border-radius: 5px;
  background-color: #fff;
}
.information_item_left > img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.information_item_right {
  flex: 1;
  line-height: 20px;

  .title {
    margin: 6px 0 20px 0;
    height: 40px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 16px;
  }
  .date {
    font-size: 12px;
    color: $font-color-secondary;
  }
}
</style>
